const axios = require("axios").default;

const backendUrl = process.env.VUE_APP_BACKEND_URL;

class Backend {
  static backendRequest(url, params) {
    const requestUrl = backendUrl + url;
    console.log("REQUEST URL", requestUrl);
    return axios.post(requestUrl, params,
      { headers: { "Content-type": "application/json" } })
  }

  static fetchData(options) {
    return Backend.backendRequest("/fetch_data", options);
  }

  static delete(options) {
    console.log("Backend.delete options:", options);
    return Backend.backendRequest("/delete", options);
  }

  static store(data) {
    return Backend.backendRequest("/store", data);
  }

  static login(credentials) {
    return Backend.backendRequest("/login", credentials);
  }

  static logout() {
    return Backend.backendRequest("/logout");
  }

  static randomWriteupId() {
    return Backend.backendRequest("/random_writeup_id");
  }
}

export default Backend;
