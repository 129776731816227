<template>
  <article class="message">
    <div v-if="header" class="message-header">
      {{header}}
      <button v-if="showDeleteButton" class="delete" aria-label="delete"></button>
    </div>
    <div class="message-body">
      <slot></slot>
    </div>
  </article>
</template>

<script>

export default {
  name: "Message",
  props: {
    header: String,
    showDeleteButton: Boolean
  }
}

</script>
